/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: 'nanum-base';
  src: url("./static/fonts/nanum/NanumSquareR.otf")
}

@font-face{
  font-family: 'nanum-light';
  src: url("./static/fonts/nanum/NanumSquareL.otf")
}

@font-face{
  font-family: 'nanum-bold';
  src: url("./static/fonts/nanum/NanumSquareB.otf")
}

@font-face{
  font-family: 'nexon-base';
  src: url("./static/fonts/nexon/nexonOTF.otf")
}

@font-face{
  font-family: 'nexon-light';
  src: url("./static/fonts/nexon/nexonLight.otf")
}

@font-face{
  font-family: 'nexon-bold';
  src: url("./static/fonts/nexon/nexonBold.otf")
}

@font-face{
  font-family: 'gmarketBold';
  src: url("./static/fonts/gmarket/GmarketSansTTFBold.ttf")
}

@font-face{
  font-family: 'gmarketLight';
  src: url("./static/fonts/gmarket/GmarketSansTTFLight.ttf")
}

@font-face{
  font-family: 'gmarketMedium';
  src: url("./static/fonts/gmarket/GmarketSansTTFMedium.ttf")
}

.h-max{
  height: max-content; 
}

.h-100{
  height: 28rem; 
}
.h-104{
  height: 32rem; 
}
.h-108{
  height: 36rem; 
}

.w-76{
  width: 19rem; 
}

.transition-width{
	transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.max-h-calendar{
  max-height : 30em; 
}

/* CSS define related to coupone  */

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #f43f5e;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  /* margin: 10% auto; */
  box-shadow: inset 0px 0px 0px #f43f5e;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-width: 4;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
  100% {
      stroke-dashoffset: 0
  }
}

@keyframes scale {

  0%,
  100% {
      transform: none
  }

  50% {
      transform: scale3d(1.1, 1.1, 1)
  }
}

@keyframes fill {
  100% {
      box-shadow: inset 0px 0px 0px 30px #f43f5e
  }
}

/* Original content */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
